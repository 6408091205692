/* utilities.css */
.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fullWidth {
    width: 100%;
}

.text-muted-foreground {
    color: var(--colorV2-grey-dark);
}

.text-destructive {
    color: var(--color-destructive-text);
}

.bottom-0 {
    bottom: 0;
}

.top-0 {
    top: 0;
}

.bg-white {
    background-color: white;
}

.border-t {
    border-top: 1px solid var(--color-border);
}

.border-b {
    border-bottom: 1px solid var(--color-border);
}

.cursor-default {
    cursor: default;
}

.list-disc {
    list-style-type: disc;
}

.list-inside {
    list-style-position: inside;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    white-space: nowrap;
}
