/* links.css */
.nakedGreenLink {
    color: var(--colorV2-dark-green);
    text-decoration: none;
    transition: color 150ms ease-in-out;

    &:hover {
        color: var(--colorV2-green);
    }
    &:focus {
        color: var(--colorV2-green);
    }
}

.nakedDarkGreenLink {
    color: var(--colorV2-darkest-green);
    text-decoration: none;
    transition: color 150ms ease-in-out;

    &:hover, &:focus {
        color: var(--colorV2-dark-green);
        text-decoration: underline;
    }
}

.darkGreenLink {
    color: var(--colorV2-darkest-green);
    text-decoration: underline;

    &:hover, &:focus {
        color: var(--colorV2-dark-green);
    }
}

.containsDarkGreenLink {
/*    any anchors inside should have darkGreenLink */
    a {
        color: var(--colorV2-darkest-green);
        text-decoration: underline;

        &:hover, &:focus {
            color: var(--colorV2-dark-green);
        }
    }
}

.containsDarkGreenLink {
    a {
        color: var(--colorV2-darkest-green);
        transition: color 150ms ease-in-out;

        &:hover, &:focus {
            color: var(--colorV2-dark-green);
        }
    }
}

.containsNakedDarkGreenLink {
    a {
        color: var(--colorV2-darkest-green);
        text-decoration: none;
        transition: color 150ms ease-in-out;

        &:hover, &:focus {
            color: var(--colorV2-dark-green);
            text-decoration: underline;
        }
    }
}
