/* variables.css */
:root {
    --global-padding: 0 90px;
    --spacer-size-1: 4px;
    --spacer-size-2: 8px;
    --spacer-size-3: 12px;
    --spacer-size-4: 16px;
    --spacer-size-5: 24px;
    --spacer-size-6: 32px;
    --spacer-size-7: 40px;
    --spacer-size-8: 48px;
    --spacer-size-9: 64px;
    --spacer-size-10: 96px;

    --font-size-48: 48px;
    --font-size-46: 46px;
    --font-size-32: 32px;
    --font-size-26: 26px;
    --font-size-24: 24px;
    --font-size-22: 22px;
    --font-size-20: 20px;
    --font-size-18: 18px;
    --font-size-16: 16px;
    --font-size-14: 14px;
    --font-size-12: 12px;

    --color-text: black;
    --primary-background-color: var(--green);
    --primary-color: white;

    --green-dark: hsl(166, 37%, 23%);
    --green-170-46-95: hsl(170, 46%, 95%);
    --green-167-37-73: hsl(167, 37%, 73%);
    --green-167-77-54: hsl(167, 77%, 54%);
    --green-over: hsl(167, 49%, 48%);
    --green: hsl(167, 49%, 44%);
    --green-medium: hsl(167, 64%, 78%);
    --green-low-over: hsl(167, 51%, 95%);
    --green-low: hsl(167, 51%, 91%);
    --green-low-0: hsla(166, 16%, 67%);
    --green-158-12-54: hsl(158, 12%, 54%);
    --green-loading: hsl(167, 49%, 66.4%);

    --white: white;
    --black: #373736;
    --black-low: hsl(0, 0%, 34%);

    /* https://github.com/radix-ui/colors/blob/main/src/blackA.ts */
    --blackA8: hsla(0, 0%, 0%, 0.22);
    --blackA7: hsla(0, 0%, 0%, 0.141);
    --blackA9: hsla(0, 0%, 0%, 0.439);

    --grey-dark-2: hsl(240, 2%, 57%);
    --grey-dark-1: hsl(0, 0%, 57%);
    --grey-02: hsl(0, 5%, 96%);
    --grey-hsl-0-4-95: hsl(0, 4%, 95%);
    --grey-hsl-0-0-83: hsl(0, 0%, 83%);
    --grey-medium-over: hsl(0, 0%, 86%);
    --grey-medium: hsl(0, 0%, 90%);
    --grey-light: hsl(0, 0%, 97%);
    --grey-light-semi: hsla(0, 0%, 97%, 0.3);
    --grey-167-1-94: hsl(167, 1%, 94%);

    --grey-low: hsl(0, 0%, 98%);

    --red: hsl(349, 89%, 65%);
    --red-dark: hsl(0, 88%, 61%);
    --red-low: hsl(0, 100%, 95%);

    --yellow: hsl(45, 100%, 72%);
    --colorV2-darkest-green: #255147;
    --colorV2-darkest-green-hsl: 166, 38%, 22%;

    --colorV2-dark-green: #027A64;
    --colorV2-dark-green-hsl: 168, 97%, 25%;

    --colorV2-medium-green: #5AA590;
    --colorV2-medium-green-hsl: 163, 29%, 50%;

    --colorV2-green: #79DBC0;
    --colorV2-green-hsl: 164, 58%, 66%;

    --colorV2-green-over: #a5ead5;
    --colorV2-green-over-hsl: 160, 57%, 80%;

    --colorV2-light-green: #DDF4EF;
    --colorV2-light-green-hsl: 164, 54%, 91%;

    --colorV2-beige: #F0E9CE;
    --colorV2-beige-hsl: 49, 55%, 87%;

    --colorV2-beige-semi: #F0E9CE66;
    --colorV2-beige-semi-hsl: 49, 55%, 87%;

    --colorV2-beige-over: #F3EDD7;
    --colorV2-beige-over-hsl: 46, 61%, 90%;

    --colorV2-beige-outline-over: #f9f7ed;
    --colorV2-beige-outline-over-hsl: 49, 66%, 96%;

    --colorV2-pink: #F6C6B4;
    --colorV2-pink-hsl: 17, 78%, 83%;

    --colorV2-grey-light: #FAFAFA;
    --colorV2-grey-light-hsl: 0, 0%, 98%;

    --colorV2-grey-light-medium: #F5F5F5;
    --colorV2-grey-light-medium-hsl: 0, 0%, 96%;

    --colorV2-grey-medium: #E6E6E6;
    --colorV2-grey-medium-hsl: 0, 0%, 90%;

    --colorV2-grey-medium-dark: #D9D9D9;
    --colorV2-grey-medium-dark-hsl: 0, 0%, 85%, 1;

    --colorV2-grey-dark: #828282;
    --colorV2-grey-dark-hsl: 0, 0%, 51%;

    --colorV2-black: #373736;
    --colorV2-black-hsl: 60, 2%, 21%;

    --color-transparent: rgb(0 0 0 / 0);
    --color-transparent-hsl: 0, 0%, 0%, 0%;

    --color-white-transparent: rgba(246, 246, 246, 0.9);

    /* non palette colors */
    --colorV2-red: rgb(243, 70, 70);
    --colorV2-red-hsl: 0, 89%, 61%;

    --colorV2-red-semi: rgba(243, 70, 70, 0.12);
    --colorV2-red-semi-hsl: 0, 89%, 61%, 0.12;

    /* These are based on the palettes we get from radix themes, to make them re-usable */
    --color-border: var(--gray-7); /* Medium gray for border */
    --muted-foreground: var(--colorV2-grey-dark-hsl);
    --color-foreground: var(--colorV2-black);
    --color-destructive-border: var(--tomato-9); /* Tomato for destructive border */
    --color-destructive: var(--tomato-3); /* Lighter tomato for background */
    --color-destructive-foreground: var(--tomato-12); /* Dark tomato for text */
    --color-destructive-hover: var(--tomato-7); /* Tomato for hover state */
    --color-destructive-focus: var(--tomato-9); /* Tomato for focus ring */
    --color-destructive-offset: var(--tomato-10); /* Tomato for focus offset */
    --color-destructive-text: var(--tomato-11);
    --color-destructive-hover-text: var(--tomato-12); /* Dark tomato for close button hover */
    --color-secondary: var(--teal-3); /* Teal for hover background */
    --color-ring: var(--teal-9); /* Teal for focus ring */

    font-family: var(--general-sans-font), Helvetica, sans-serif;


    /* These I am adding as we begin the dashboard v2 migration, might want to consolidate with above */
    --background-color: #ffffff;
    --secondary-bg-color: #f3f4f6;
    --text-foreground: var(--colorV2-black);
    --text-muted-foreground: #6b7280;
    
}
